import React, { Component } from "react";
import Fade from "react-reveal";

class Interview extends Component {
  render() {
    return (
      <section id="interview">
        <Fade bottom duration={1000}>
          <div className="row section-head">
              <h1>
                <span>FM 903 Interview</span>
              </h1>
              <div class="video-container">
                <iframe title="uniqueID" src="https://www.youtube.com/embed/wxyBSQLnJvU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Interview;
